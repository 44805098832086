<template>
    <router-view/>
</template>
<style lang="scss">
@import "~spectre.css";

body {
    overscroll-behavior-y: contain;
}

#app {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    align-items: center;
    text-align: center;

    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.flex-1 {
    flex: 1;
}
</style>
