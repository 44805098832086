import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue';
import Page from '../views/Page.vue';
import Log from '../views/Log.vue';
import Respondent from "../views/Respondednt";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/upload',
        name: 'Upload',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "upload" */ '../views/Upload.vue')
    },
    {
        path: '/page/:pageNumber',
        name: 'Page',
        component: Page
    },
    {
        path: '/log',
        name: 'Log',
        component: Log
    },
    {
        path: '/staff',
        name: 'Respondent',
        component: Respondent
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
