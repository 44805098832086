import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import _ from "lodash";
import IdbService from "./services/IdbService";
import Store from './store';

import bootstrap from './bootstrap.js';

_.forEach(window.surveyConfig.languages, (language) => {
    IdbService.getTranslations(language)
        .finally(() => {

        });
})

IdbService.getTranslationsEnabled();

IdbService.getLang();

IdbService.getRespondentId();

IdbService.getCurrentId()
    .finally(() => {
        IdbService.getAnswers(Store.data.currentId)
    });

setTimeout(() => {
    const app = createApp(App)
        .use(router)
        .mount('#app');
}, 200)


