<template>
    <div class="question question-single-choice">
        <div class="question-title" v-if="question.text">
            <trans :translationKey="question.text"></trans>
        </div>

        <div class="question-instructions" v-if="question.instructions">
            <trans :translationKey="question.instructions"></trans>
        </div>

        <div class="question-desc" v-if="question.desc">
            <trans :translationKey="question.desc"></trans>
        </div>

        <div class="answers" :style="answerWidth">
            <div class="form-group">
                <select class="form-select" v-model="value">
                    <option value="false"><trans :translationKey="question.empty"></trans></option>
                    <option v-for="(value, key) in question.answers"
                            :value="key"
                            :key="key"
                            v-text="getOption(key, value)"
                    ></option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
import StoreContainer from '../../store';
import emitter from "../../services/Mitt";
import Trans from "../Trans";
import _ from 'lodash';
import __ from '../../services/__';

export default {
    props: [
        'question'
    ],

    components: {
        Trans
    },

    data() {
        return {
            value: false,
            store: StoreContainer.data
        }
    },

    mounted() {
        this.getValuesFromStore();
    },

    computed: {
        answerWidth() {
            if(typeof this.question.width === 'undefined' || !this.question.width) {
                return {};
            }

            return {
                width: this.question.width + 'px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },

    methods: {
        getValuesFromStore() {
            if(typeof this.store.answers[this.question.id] !== 'undefined') {
                this.value = this.store.answers[this.question.id];
            }
        },

        getOption(key, value) {
            if(_.indexOf(this.question.texts, key) !== -1) {
                return __(value, this.store.currentLanguage);
            }

            return key;
        }
    },

    watch: {
        value(newValue) {
            this.store.answers[this.question.id] = newValue;
        }
    }
}
</script>
