export default {
    structure: {},
    downloadedAt: false,
    version: false,

    getPage(pageNumber) {
        return this.structure[pageNumber]
    },

    setStructure(data) {
        this.version = data.version;

        this.downloadedAt = data.downloadedAt;

        this.structure = data.structure;
    },

    getVersion() {
        return this.version;
    },

    getDownloadTime() {
        return this.downloadedAt;
    }
}