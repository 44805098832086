<template>
    <div class="home">

        <div class="respondent">
            <div class="padded-div">
                <div class="form-group">
                    <label class="form-label" for="respondent">Jméno (kód) tazatele</label>
                    <input class="form-input text-center" type="text" id="respondent" v-model="respondentId">
                </div>
                <button
                    @click="saveRespondentId()"
                    class="btn btn-primary btn-lg"
                >uložit</button>
            </div>
            <div  class="padded-div">

            </div>
        </div>

    </div>
</template>

<script>
import IdbService from "../services/IdbService";
import StoreContainer from "../store";

export default {
    name: 'Respondent',

    data() {
        return {
            store: StoreContainer.data,
            respondentId: ''
        }
    },

    mounted() {
        this.respondentId = this.store.respondentId;
    },

    methods: {
        saveRespondentId(timestamp) {

            IdbService.setRespondentId(this.respondentId)
                .finally(() => {
                    this.store.respondentId = this.respondentId;

                    this.$router.push('/')
                })

        },
    },

    watch: {
        $route(to, from) {
            this.respondentId = this.store.respondentId;
        }
    }
}
</script>
<style>
.respondent label {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}

.respondent input {
    font-size: 2em;
    height: 2.5em;
}

.respondent .btn {
    height: 3em;
}
</style>
