export default {
    data: {
        applicationInitialized: false,

        answers: {},

        pageTimes: {},

        translationsEnabled: true,

        currentLanguage: false,

        currentId: false,

        respondentId: '',

        started: false
    },

    getAnswer(answerId, type) {
        if(typeof this.data.answers[answerId] === 'undefined') {
            return false;
        }

        let value = this.data.answers[answerId];

        if(type === 'int') {
            value = parseInt(value);
        }

        return value;
    }
}