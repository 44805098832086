<template>
    <span v-html="output"></span>
    <a v-if="store.translationsEnabled"
       target="_blank"
       :href="'https://lir2022.baolab.cz/translate/' + translationKey +'/' + store.currentLanguage"
       @click.stop=""
       style="margin-left: 1em;"
    >[t]</a>
</template>

<script>
import __ from "../services/__";
import StoreContainer from "../store";

export default {
    props: [
        'translationKey'
    ],

    data() {
        return {
            store: StoreContainer.data
        }
    },

    computed: {
        output() {
            let output = '';

            output = __(this.translationKey, this.store.currentLanguage);

            return output;
        }
    },
}
</script>