<template>
    <div class="question question-single-choice">
        <div class="question-title" v-if="question.text">
            <trans :translationKey="question.text"></trans>
        </div>

        <div class="question-title"
             v-if="question.nonTranslatedText"
             v-html="question.nonTranslatedText"
        ></div>

        <div class="question-instructions" v-if="question.instructions">
            <trans :translationKey="question.instructions"></trans>
        </div>

        <div class="question-desc" v-if="question.desc">
            <trans :translationKey="question.desc"></trans>
        </div>

        <div class="answers" :style="answerWidth">
            <label
                v-for="(answerText, answerId) in question.answers"
                :key="question.id + '_' + answerId"
                class="form-checkbox answer"
                :class="{selected: isSelected(answerId) }"
            >
                <input type="checkbox"
                       :name="'answer' + question.id + '_' + answerId"
                       @click="recordValue(answerId)"
                       :checked="isSelected(answerId)"
                >
                <i class="form-icon"></i>
                <trans :translationKey="answerText"></trans>
            </label>
        </div>

        <div class="comment" v-if="showComment" :style="answerWidth">
            <div class="form-group">
                <label class="form-label"
                       :for="commentId">
                    <trans :translationKey="question.commentText"></trans>
                </label>
                <input class="form-input"
                       type="text"
                       v-model="commentValue"
                       :id="commentId"
                >
            </div>
        </div>
    </div>
</template>
<script>
import StoreContainer from '../../store';
import emitter from "../../services/Mitt";
import Trans from "../Trans";

export default {
    props: [
        'question'
    ],

    components: {
        Trans
    },

    data() {
        return {
            commentValue: '',

            store: StoreContainer.data
        }
    },

    mounted() {
        this.getValuesFromStore();
    },

    computed: {
        showComment() {
            return this.question.comment && String(this.store.answers[this.question.id + '_' + this.question.comment]) === '1'
        },

        commentId() {
            if(!this.question.comment) {
                return false;
            }

            return this.question.id + '_' + this.question.comment + '_' + 'comment';
        },
        answerWidth() {
            if(typeof this.question.width === 'undefined' || !this.question.width) {
                return {};
            }

            return {
                width: this.question.width + 'px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },

    methods: {
        getValuesFromStore() {

            if(typeof this.store.answers[this.question.id] !== 'undefined') {
                this.value = this.store.answers[this.question.id];
            }

            if(typeof this.store.answers[this.commentId] !== 'undefined') {
                this.commentValue = this.store.answers[this.commentId];
            }
        },

        isSelected(answerId) {
            return String(this.store.answers[this.question.id + '_' + answerId]) === '1'
        },

        recordValue(answerId) {
            if(
                typeof this.store.answers[this.question.id + '_' + answerId] === 'undefined'
                || String(this.store.answers[this.question.id + '_' + answerId]) !== '1'
            ) {
                this.store.answers[this.question.id + '_' + answerId] = 1
            } else {
                this.store.answers[this.question.id + '_' + answerId] = 0
            }
        }
    },

    watch: {
        value(newValue) {
            this.store.answers[this.question.id] = newValue;
        },

        commentValue(newValue) {
            this.store.answers[this.commentId] = newValue;
        }
    }
}
</script>
<style lang="scss">


</style>