<template>
    <div class="question question-grid">
        <div class="question-title" v-if="question.text">
            <trans :translationKey="question.text"></trans>
        </div>

        <div class="question-instructions" v-if="question.instructions">
            <trans :translationKey="question.instructions"></trans>
        </div>

        <div class="question-desc" v-if="question.desc">
            <trans :translationKey="question.desc"></trans>
        </div>

        <div class="answers"
             v-for="(subQuestion, subQuestionKey) in subQuestions"
             :key="subQuestionKey"
        >
            <div class="subQuestion-title" v-if="subQuestion">
                <trans :translation-key="subQuestion"></trans>
            </div>
            <div class="answer-grid answer-grid-side-values">
                <div>
                    <trans :translation-key="question.sideValues[subQuestionKey][0]"></trans>
                </div>
                <div v-for="optionKey in question.numberOfOptions"
                     :key="optionKey"
                     :style="cellWidth"
                     @click="setAnswer(subQuestionKey, optionKey)"
                     :class="{'selected': isSelected(subQuestionKey, optionKey)}"
                >
                    {{ optionKey }}
                </div>
                <div>
                    <trans :translation-key="question.sideValues[subQuestionKey][1]"></trans>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StoreContainer from '../../store';
import emitter from "../../services/Mitt";
import Trans from "../Trans";

function shuffleObject(obj){
    // new obj to return
    let newObj = {};
    // create keys array
    var keys = Object.keys(obj);
    // randomize keys array
    keys.sort(function(a,b){return Math.random()- 0.5;});
    // save in new array
    keys.forEach(function(k) {
        newObj[k] = obj[k];
    });
    return newObj;
}

export default {
    props: [
        'question'
    ],

    components: {
        Trans
    },

    data() {
        return {
            store: StoreContainer.data
        }
    },

    mounted() {
        this.getValuesFromStore();
    },

    computed: {
        subQuestions() {
            if(typeof this.question.rotate !== 'undefined' && this.question.rotate) {
                return shuffleObject(this.question.subQuestions)
            }

            return this.question.subQuestions;
        },

        answerWidth() {
            if (typeof this.question.width === 'undefined' || !this.question.width) {
                return {};
            }

            return {
                width: this.question.width + 'px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },

        cellWidth() {
            if (typeof this.question.cellWidth === 'undefined' || !this.question.cellWidth) {
                return {};
            }

            return {
                'min-width': this.question.cellWidth + 'px',
            }
        }
    },

    methods: {
        getValuesFromStore() {
            if (typeof this.store.answers[this.question.id] !== 'undefined') {
                this.value = this.store.answers[this.question.id];
            }
        },

        setAnswer(subQuestion, option) {
            let answerKey = this.question.id + '_' + subQuestion;

            this.store.answers[answerKey] = option;
        },

        isSelected(subQuestion, option) {
            let answerKey = this.question.id + '_' + subQuestion;

            if (typeof this.store.answers[answerKey] === 'undefined') {
                return false;
            }

            if (this.store.answers[answerKey] === option) {
                return true;
            }

            return false;
        }
    }
}
</script>
<style>
.answer-grid.answer-grid-side-values > div {
    font-size: 0.9em;
}
</style>