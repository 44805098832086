<template>
    <div class="question question-grid">
        <div class="question-title" v-if="question.text">
            <trans :translationKey="question.text"></trans>
        </div>

        <div class="question-instructions" v-if="question.instructions">
            <trans :translationKey="question.instructions"></trans>
        </div>

        <div class="question-desc" v-if="question.desc">
            <trans :translationKey="question.desc"></trans>
        </div>

        <div class="answers"
             v-for="(subQuestion, subQuestionKey) in question.subQuestions"
             :key="subQuestionKey"
        >
            <div class="subQuestion-title">
                <trans :translation-key="subQuestion"></trans>
            </div>
            <div class="answer-grid">
                <div v-for="(text, optionKey) in question.options"
                     :key="optionKey"
                     :style="cellWidth"
                     @click="setAnswer(subQuestionKey, optionKey)"
                     :class="{'selected': isSelected(subQuestionKey, optionKey)}"
                >
                    <trans :translation-key="text"></trans>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StoreContainer from '../../store';
import emitter from "../../services/Mitt";
import Trans from "../Trans";

export default {
    props: [
        'question'
    ],

    components: {
        Trans
    },

    data() {
        return {
            store: StoreContainer.data
        }
    },

    mounted() {
        this.getValuesFromStore();
    },

    computed: {
        answerWidth() {
            if (typeof this.question.width === 'undefined' || !this.question.width) {
                return {};
            }

            return {
                width: this.question.width + 'px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },

        cellWidth() {
            if (typeof this.question.cellWidth === 'undefined' || !this.question.cellWidth) {
                return {};
            }

            return {
                'min-width': this.question.cellWidth + 'px',
            }
        }
    },

    methods: {
        getValuesFromStore() {
            if (typeof this.store.answers[this.question.id] !== 'undefined') {
                this.value = this.store.answers[this.question.id];
            }
        },

        setAnswer(subQuestion, option) {
            let answerKey = this.question.id + '_' + subQuestion;

            this.store.answers[answerKey] = option;
        },

        isSelected(subQuestion, option) {
            let answerKey = this.question.id + '_' + subQuestion;

            if (typeof this.store.answers[answerKey] === 'undefined') {
                return false;
            }

            if (this.store.answers[answerKey] === option) {
                return true;
            }

            return false;
        }
    }
}
</script>
<style>
.answer-grid {
    display: flex;
    margin: 0 1em;
    width: 100%;
    justify-content: center;
}

.answer-grid > div {
    padding: 0.5em;
    border: 1px solid lightgrey;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}
.answer-grid > div.selected {
    background-color: lightblue;
}


.answer-grid > div > span {
    display: block;
}

.subQuestion-title {
    font-weight: 600;
    margin-bottom: 0.5em;
}

.question-grid {
    width: 100%;
}
</style>