<template>
    <div class="page">
        <div class="page-content" style="margin-top: 1em; padding: 3em 10em;">
            <div style="display: flex; min-width: 600px; text-align: left; align-items: center; margin-bottom: 2em;">
                <div style="flex: 1;">
                    <h1 style="margin-bottom: 0;">Záznamy</h1>
                </div>
                <div>
                    <button class="btn btn-secondary btn-lg"
                            @click="$router.push('/')"
                    >zpět
                    </button>
                </div>
            </div>


            <div class="log-records"
                 v-for="response in sortedResponses"
                 :key="response.id"
            >
                <div class="record">
                    <div style="width: 3em;"># {{ response.id }}</div>
                    <div style="width: 7em;">{{ response.respondentId }}</div>s
                    <div style="width: 13em;">start: {{ formatDateTime(response.started) }}</div>
                    <div style="width: 5em;">ans.: {{ Object.keys(response.answers).length }}</div>
                    <div style="flex: 1;"></div>
                    <div v-html="getStatus(response)"></div>
                </div>
            </div>

            <div style="margin-top: 3em;">
                <button class="btn btn-primary btn-lg"
                        @click="uploadResponses()"
                        :disabled="uploading"
                >Nahrát sezbíraná data
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {getMany, keys} from "idb-keyval";
import _ from "lodash";
import StoreContainer from '../store';
//import http from "../services/HttpLocal";
import http from "../services/Http";
import IdbService from "../services/IdbService";

export default {
    data() {
        return {
            responses: [],
            store: StoreContainer.data,
            uploading: false
        }
    },

    mounted() {
        this.loadResponses();
    },

    computed: {
        sortedResponses() {
            return _.orderBy(this.responses, ['started'], 'desc');
        }
    },

    methods: {
        formatDateTime(timestamp) {

            let date = new Date()

            date.setTime(timestamp);

            return date.toLocaleDateString(
                'cs-CZ',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                }
            ) + ' ' + date.toLocaleTimeString(
                'cs-CZ'
            );
        },

        loadResponses() {
            let responseKeys = [];

            keys().then((keys) => {
                _.forEach(keys, (key) => {
                    if (key.includes('x_resp_')) {
                        responseKeys.push(key)
                    }
                })

                // It's faster to do:
                getMany(responseKeys).then((results) => {
                    _.forEach(results, (result) => {
                        this.responses.push(result)
                    })
                });
            });
        },

        getStatus(response) {
            if (response.uploaded2 === true) {
                return '<span class="text-success text-bold">odesláno</span>';
            }

            if (response.id === this.store.currentId) {
                return '<span class="text-primary">aktivní</span>';
            }

            return '<span class="text-error text-bold">neodesláno</span>';
        },

        uploadResponses() {

            this.uploading = true;

            _.forEach(this.responses, (result) => {
                if (( typeof result.uploaded2 === 'undefined' || !result.uploaded2) && result.id !== this.store.currentId) {
                    http.post('/save-response', result).then(() => {
                        IdbService.markUploaded2(result.id)
                            .finally(() => {
                                result.uploaded2 = true;
                            })
                    });
                }
            })

            setTimeout( () => {
                this.uploading = false;
            }, 10000)
        }
    }
}
</script>
<style>
.log-records {
    min-width: 600px;
    font-size: 1.3em;
}

.record {
    display: flex;
    text-align: left;
    border: 1px lightgrey solid;
    padding: 0.3em 1em;
}

.record > div {
    margin-right: 1em;
}

.record > div:last-child {
    margin-right: 0;
}
</style>