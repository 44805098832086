import StoreContainer from "../store";
import IdbService from "../services/IdbService";

const postProcessHooks = {
    page1: (page) => {
        if (StoreContainer.getAnswer('T2', 'int') === 2) {
            return 10
        }

        return page.next;
    },

    page3: (page) => {
        if (StoreContainer.getAnswer('PA', 'int') === 2) {
            return 10
        }

        return page.next;
    },

    page21: (page) => {
        let COUNTRYALL = StoreContainer.data.answers.COUNTRY;

        if (StoreContainer.data.answers.COUNTRY === 'other') {
            COUNTRYALL = StoreContainer.data.answers.COUNTRY_other_comment;
        }
        StoreContainer.data.answers.COUNTRYALL = COUNTRYALL;

        return page.next;
    },

    page104: (page) => {
        if (
            !(
                StoreContainer.data.answers['Ekok'] === '1' ||
                StoreContainer.data.answers['T0'] === '2'
            )
        ) {
            return 180;
        }


        return page.next;
    },

    page500: (page) => {
        StoreContainer.data.answers.END = 1;

        return page.next;
    }

}

export default postProcessHooks;
